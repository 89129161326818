<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
      <b-spinner variant="primary" label="Text Centered" />     
    </div>
    <div v-else>
        <modal-car-condition-edit-vue :idCarCondition="idCarCondition" @refresh="refresh"/>
        <tableConditionCar :carConditions="carConditions" @editForm="editForm"/>
        <modal-car-condition @refresh="refresh"/>
    </div>
    <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
  </div>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import ModalCarConditionEditVue from '@/views/component/Modal/ModalCarCondition/ModalCarConditionEdit.vue'
import ModalCarCondition from '@/views/component/Modal/ModalCarCondition/ModalCarCondition.vue'
import tableConditionCar from '@/views/component/Table/tableConditionCar.vue'
export default {
    components:{
        ModalCarConditionEditVue,
        ToastificationContent,
        ModalCarCondition,
        tableConditionCar
    },
    data() {
        return {
            idCarCondition: null,
            carConditions: [],
            showPreloader: false,
            fields: [
            { key: 'id', label: 'Id'},
            { key: 'name', label: 'Имя'},
            { key: 'level', label: 'Уровень'},
        ],
        }
    },
    mounted() {
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
    },
    methods: {
        openFilter() {
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            }); 
            if (arrayRoutes.includes(this.$route.path) == true){
                this.showPreloader = true
                this.$http
                .get(`${this.$route.name}`,{params: param}).then(res => {
                    this.carConditions = res.data
                    this.$store.commit('pageData/setdataCount', this.carConditions.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
            }else if(arrayRoutes.includes(this.$route.path) == false){
        this.$bvModal.show(this.$route.name + 'filter')
      }
        },
        sendToParent(tableData){
		 this.carConditions = tableData
         this.$store.commit('pageData/setdataCount', this.carConditions.length)
	  },
        refresh() {
            this.showPreloader = true
            this.$http
            .get('car-settings/car-conditions/')
            .then(res => {
                this.carConditions = res.data
                this.$store.commit('pageData/setdataCount', this.carConditions.length)
                this.showPreloader = false
                this.$store.commit('REFRESH_DATA', false)
            }).catch(err => {
                this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Ошибка!',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: err.response.data.errors,
          },
        })
            })
        },
        editForm(id) {
            this.idCarCondition = id.id
            this.$bvModal.show('editCarCondition')
        }
    },
    computed: {
       fetchingNewData() {
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val) {
            if(val) {
                let params = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
                this.$http
                .get(`${this.$route.name}`, {param: params})
                .then(res =>{
                    this.carConditions = res.data
                    this.$store.commit('pageData/setdataCount', this.carConditions.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
            }
        }
    }
}
</script>