<template>
    <b-row>
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            @row-dblclicked="editForm"
            @row-clicked="clicked"
            :items="carConditions"
            :fields="fields"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              ></b-form-checkbox>
            </template>
            <template #cell(id)="data">
              {{ data.value }}
            </template>
            <template #cell(name)="data">
              {{ data.value }}
            </template>
            <template #cell(level)="data">
              {{ data.value }}
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
  props: ['carConditions'],
  data() {
    return {
      fields: [
        { key: 'id', label: 'Id', sortable: true, thStyle: { width: '40px' } },
        { key: 'name', label: 'Имя', sortable: true, thStyle: { width: '150px' } },
        { key: 'level', label: 'Уровень', sortable: true, thStyle: { width: '150px' } },
      ],
    }
  },
  mounted() {
    resizeable()
  },
  methods:{
    editForm(id) {
            this.$emit('editForm', id)
        },
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index

      const clickedElement = event.target;
      const clickedCell = clickedElement.closest('td');

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
      cellSelect()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';

table {
  outline: none;
  table-layout: fixed;
  user-select: none;
  overflow: hidden;
  position: relative;
}

tr, td, th {
  padding: 0;
}

td, th {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  box-sizing: border-box;
  min-width: 60px;
}
</style>
