<template>
  <b-modal
  id="editCarCondition" 
  title="Редактирование состояние автомобиля" 
	size="sm" 
	hide-footer 
	no-enforce-focus 
	:no-close-on-backdrop="true"
  @shown="getCarCondition"
  @close="getClearCarCondition">
  <b-row>
    <b-col>
      <label>Название</label>
      <b-input v-model="isCarCondition.name" placeholder="Название" />
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <label>Уровень</label>
      <b-input v-model="isCarCondition.level" placeholder="Уровень" />
    </b-col>
  </b-row>
  <b-row>
      <b-col cols="12" >
        <b-button
        :disabled="submitButtonDisabled"
        class="mt-2 col-md-5"
        variant="primary"
        @click="updateCarCondition"
        >Изменить</b-button>
        <b-button class="mt-2 ml-5 col-md-5" variant="secondary" @click="getClearCarCondition">
         Отменить
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
export default {
  props:['idCarCondition'],
  components:{
    ToastificationContent
  },
  data() {
    return {
      isCarCondition: {
        name: '',
        level: null,
      },
      submitButtonDisabled: false,
    }
  },
  methods:{
    getCarCondition() {
      this.$http
				.get(`car-settings/car-conditions/${this.idCarCondition}/edit`)
				.then((res) => {
				this.isCarCondition = res.data;
				})
				.catch((err) => {
				this.$toast({
					component: ToastificationContent,
					position: 'top-right',
					props: {
						title: 'Ошибка!',
						icon: 'XIcon',
						variant: 'danger',
						text: err.response.data.errors,
					},
				});
				});
    },
    updateCarCondition() {
        this.submitButtonDisabled = true
        this.$http.patch(`car-settings/car-conditions/${this.idCarCondition}`, this.isCarCondition)
        .then(() => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Cостояния автомобиля изменено!',
                    icon: 'CheckIcon',
                    variant: 'success',
                },
            })
            this.$emit('refresh')
            this.getClearCarCondition()
        })
        .catch(err => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Некорректные cостояния!',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: err.response.data.errors, 
                },
            })
        })
        .finally(() => {
            this.submitButtonDisabled = false
        })
    },
    getClearCarCondition() {
      this.isCarCondition = {
        id: null,
        name: '',
        level: null,
      }
      this.$bvModal.hide('editCarCondition')
    }
  }
}
</script>

<style>

</style>