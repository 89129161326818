<template>
  <div>
    <b-modal
    :id="$route.name + 'CreateModal'"
    title="Создать состояния автомобиля"
    size="sz"
    hide-footer
    no-enforce-focus
    :no-close-on-backdrop="true"
    @close="closeModal"
    >
    <b-row>
        <b-col>
            <label>Название</label>
            <b-input v-model="condition.name" placeholder="Название"/>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <label>Уровень</label>   
        <b-input v-model="condition.level" type="number" placeholder="Уровень"/>
        </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" >
        <b-button
        :disabled="submitButtonDisabled"
        class="mt-2 mr-1 col-md-5"
        variant="primary"
        @click="createCarCondition"
        >Сохранить</b-button>
        <b-button class="mt-2 ml-5 col-md-5" variant="secondary" @click="closeModal">
            Отменить
        </b-button>
      </b-col>
    </b-row>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
export default {
    components:{
        ToastificationContent
    },
data() {
    return {
        condition: {
            name: '',
            level: null,
        },
        submitButtonDisabled: false,
    }
},
methods:{
    createCarCondition() {
        this.submitButtonDisabled = true
        this.$http.post('/car-settings/car-conditions', this.condition)
        .then(() => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Cостояния автомобиля создан!',
                    icon: 'CheckIcon',
                    variant: 'success',
                },
            })
            this.$emit('refresh')
            this.closeModal()
        })
        .catch(err => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Некорректные cостояния!',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: err.response.data.errors, 
                },
            })
        })
        .finally(() => {
            this.submitButtonDisabled = false
        })

    },
    closeModal() {
        this.condition = {
            name: '',
            level: null,
        },
        this.$bvModal.hide(this.$route.name + 'CreateModal')
    }
}
}
</script>